export default {
  methods: {
    trackingUserDetails(user) {
      if (user) {
        const phoneNumber = user.phone_code + user.phone
        const formattedPhoneNumber = phoneNumber.replace(/[+\s]/g, "");
        if (typeof this.$snaptr === 'function') {
          this.$snaptr.enable({
            user_name: user.name,
            user_email: user.email,
            user_phone_number: formattedPhoneNumber
          });
        }
      }
    },

    trackingPurchaseJourney(eventName, user, product) {

      if (!window.dataLayer) { return; }
      const pushData = {
        event: eventName,
        user_name: user ? `${user.first_name} ${user.last_name}` : `${product.first_name} ${product.last_name}`,
        user_email: user ? user.email : product.user_email,
        country_name: user ? user.country_name : product.country_name,
        gender: user ? (user.gender === 'ذكر' ? 'male' : 'female') : (product.gender === 'ذكر' ? 'male' : 'female')
      };

      const handleProductArray = (products) => {
        pushData.items = products.map(item => ({
          item_name: item.name,
          item_id: item.id,
          num_items: 1,
          item_type: item.model_type || item.type,
          item_price: item.price,
          currency: item.currency || 'SAR'
        }));
        pushData.content_ids = products.map(item => item.id);
        pushData.value = products.reduce((acc, item) => acc + parseFloat(item.price), 0);
      };

      const handleSingleProduct = (prod) => {
        pushData.items = [{
          item_name: prod.name || prod.productsNames,
          item_id: prod.id || prod.productsIds,
          num_items: prod.numberOfProducts || 1,
          item_type: prod.model_type || prod.type,
          item_price: prod.price || prod.totalPrice,
          currency: prod.currency || 'SAR'
        }];
        pushData.content_ids = prod.id || prod.productsIds;
        pushData.value = parseFloat(prod.price || prod.totalPrice);
      };

      const handleCompletePayment = (prod) => {
        Object.assign(pushData, {
          event: eventName,
          user_id: prod.user_id,
          user_name: prod.user_name,
          user_email: prod.user_email,
          phone_number: `+${prod.phone_number.replace(/\D/g, '')}`,
          country_name: prod.country_name,
          gender: prod.gender,
          transaction_id: prod.transaction_id,
          payment_method: prod.payment_method,
          price: prod.totalPrice,
          currency: prod.currency || 'SAR',
          value: parseFloat(prod.totalPrice),
          items: prod.items,
          numberOfProducts: prod.items.length
        });
      };

      if (Array.isArray(product)) {
        handleProductArray(product);
      } else {
        switch (eventName) {
          case 'viewItemSkipped':
            break;
          case 'CompletePayment':
            handleCompletePayment(product);
            break;
          default:
            handleSingleProduct(product);
        }
        if (Array.isArray(product)) {
          product.forEach((item) => {
            window.dataLayer.push({
              event: eventName,
              user_name: `${user.first_name + ' ' + this.user.last_name}`,
              user_email: user.email,
              country_name: user.country_name,
              gender: user.gender === 'ذكر' ? 'male' : 'female',
              item: [{
                item_name: item.name,
                item_id: item.id,
                num_items: 1,
                item_type: item.model_type ? item.model_type : item.type,
                item_price: item.price
              }],
              value: parseFloat(item.price),
              currency: item.currency ? item.currency : 'SAR'
            });
          });
        } else if (eventName === 'viewItemSkipped') {
          window.dataLayer.push({
            event: eventName
          });
        } else {
          window.dataLayer.push({
            event: eventName,
            user_name: `${user.first_name + ' ' + this.user.last_name}`,
            user_email: user.email,
            country_name: user.country_name,
            gender: user.gender === 'ذكر' ? 'male' : 'female',
            item: [{
              item_name: product.name ? product.name : product.productsNames,
              item_id: product.id ? product.id : product.productsIds,
              num_items: product.numberOfProducts ? product.numberOfProducts : 1,
              item_type: product.model_type ? product.type : 'product',
              item_price: product.price ? product.price : product.totalPrice
            }],
            value: parseFloat(product.price ? product.price : product.totalPrice),
            currency: product.currency ? product.currency : 'SAR'
          });
        }
      }
      window.dataLayer.push(pushData);
    }

  }
}
